import React from "react"
import { Link } from "gatsby"




const Header = () => {


  

  return (
    <>
      <div className="header-container">
        <div className="nav-wrapper" >
          <Link to="/"  >
            <h2 >
              nikos<span>tzamalis.</span> 
            </h2>
          </Link>       
        </div>
      </div>
    </>
  )
}

export default Header
