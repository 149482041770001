import React from "react"
import PropTypes from "prop-types"

//COMPONENTS
import Cursor from './customCursor'
import Header from "../components/Header"
import Transition from './transition'
import "../styles/layout.scss"
import { Helmet } from "react-helmet"
import Seo from './Seo'



const Layout = ({ children, location }) => {

  const getTitle = () => {
    if(location.pathname === '/'){
      return 'Home'
    }
    else if(location.pathname === '/about'){
      return 'About'
    }
    else if(location.pathname === '/contact'){
      return 'Contact'
    }
    else if(location.pathname === '/work'){
      return 'Work'
    }
    else if(location.pathname === '/hair-salon'){
      return 'Hair Salon'
    }
    else return ''
  }

  return (
    <>
       <Helmet title={`Nikos Tzamalis | ${getTitle()}`}>
      </Helmet>
      <Cursor/>
      <Header/>
      <Transition location={location}>{children}</Transition>
 
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,

} 
export default Layout
