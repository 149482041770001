import React from 'react'
import {
  TransitionGroup,
  CSSTransition
} from 'react-transition-group'

//This variable will be responsible for our animation duration
const timeout = 200
//This object contains basic styles for animation, but you can extend them to whatever you like. Be creative!
const getTransitionStyles = {
  entering: {
    position: 'absolute',
    opacity: 0,
    
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `all ${timeout}ms ease-in-out`,
    opacity: 0,
  },
}



class Transition extends React.PureComponent {
  render() {
 
    const { children, location } = this.props


 

   /*  const onExit = node => {
       
       
            
            if(location.pathname === '/'){

              console.log(node.children[1].children[0].children[1].children[0].children[0].childNodes.item(0));

              const line1 = node.children[1].children[0].children[0].children[0].childNodes.item(0)
              const line2 = node.children[1].children[0].children[0].children[0].childNodes.item(1)  
              const line3 =  node.children[1].children[0].children[0].children[0].childNodes.item(2)
              const menu1 = node.children[1].children[0].children[1].children[0].children[0].childNodes.item(0)
              const menu2 = node.children[1].children[0].children[1].children[0].children[0].childNodes.item(1)
              const menu3 = node.children[1].children[0].children[1].children[0].children[0].childNodes.item(2)
                
                const tl = gsap.timeline()
               
                tl.to([line1 , line2, line3] ,{
                    duration:1,
                    opacity:0,
                    y: -100,
                    skewX:10,
                    ease:'Power3.easeOut',
                    stagger:{
                        amount: .5
                    }
                })
                .to([menu3, menu2, menu1], {
                  duration:1,
                  opacity:0,
                  delay:-.8,
                  y:300,
                  skewX:12,
                  x:-80,
                  rotate:20,
                  ease:'Power3.easeOut',
                  stagger:{
                    amount: .5
                  }
                })
                
               
            }else if(location.pathname === '/about'){
              alert('/about')
            }
              
          }
    
 */
    

    return (
     
      <TransitionGroup>
        <CSSTransition
          in={location.pathname != null}
          key={location.pathname}
        unmountOnExit
          timeout={{
           enter:timeout,
            exit: timeout,
          }}
         
         /*  onExit={onExit} */
        >
          {
          status => (
           
            <div
              style={{
                ...getTransitionStyles[status],
              }}
            >
              {children}
            </div>
          )}
        </CSSTransition>
      </TransitionGroup>
    )
  }
}
export default Transition