import React, { useState, useEffect } from 'react'
import '../styles/cursorStyles.scss';
import { useGlobalStateContext } from "../context/globalContext"
import {ArrowTop} from '../assets/social-icons'

const Cursor = () => {



    const { cursorType } = useGlobalStateContext()


    const [mousePosition, setMousePosition] = useState({
        x:400,
        y:400
    })

    const onMouseMove = (event) => {
        const {clientX:x, clientY:y} = event 
        setMousePosition({x,y})
    }

    useEffect(()=>{
        document.addEventListener('mousemove', onMouseMove)
        return () => {
            document.removeEventListener('mousemove', onMouseMove)
        }
    })


    return (
        <>
        <div  className= {`cursor ${cursorType}`} 
         style={{left: `${mousePosition.x}px`, top:`${mousePosition.y}px`}}>
              {cursorType === 'hovered' ? <div className="arrow"><ArrowTop/></div> : ''}
        </div>
       
        </>
    )
}

export default Cursor
