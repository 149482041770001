import React from "react"
import { GlobalProvider } from "./src/context/globalContext"

const transitionDelay = 2000

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    if (location.action === 'PUSH') {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
        const savedPosition = getSavedScrollPosition(location)
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay
        )
    }
    return false
    } 


    
    export const wrapRootElement = ({ element }) => {
      return <GlobalProvider>{element}</GlobalProvider>
    }
    